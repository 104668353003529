// 3rd party
import Link from 'next/link'

// Helpers
import { formatUrlSlug } from '@/lib/helper'
import { ImageLoader } from '@/components/Image/ImageLoader'
import { NextImage } from '@/components/Image/NextImage'
import { navClick } from '@/lib/ga-events'

const LandscapeCTA = ({ landscapeCta, fullWidth = false, onLinkClick = undefined, tabIndex }) => (
  <div id="menuItemSb" className={`relative ${fullWidth ? 'w-full' : 'w-[360px]'}`}>
    <div className={`${fullWidth ? 'container' : 'px-8'} relative z-10 h-[68px] py-3`}>
      <p className="uppercase text-white xl:text-[13px] text-[12px] tracking-[0.1em] ">
        {landscapeCta.text}
      </p>
      {landscapeCta.link &&
        (!!onLinkClick ? (
          <div
            className={`text-white uppercase font-semibold xl:text-[13px] text-[12px] leading-[16.25px]
          tracking-[0.2em] cursor-pointer transition-all hover:text-primary underline underline-offset-4 decoration-2`}
            onClick={(e) => {
              navClick(
                landscapeCta.link.link,
                landscapeCta.link.title,
                'header',
                landscapeCta.link.title.toLocaleLowerCase()
              )
              onLinkClick(e, landscapeCta.link.link)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onLinkClick(e, landscapeCta.link.link)
              }
            }}
            tabIndex={tabIndex}
            role="button"
          >
            {landscapeCta.link.title}
          </div>
        ) : (
          <Link href={formatUrlSlug(landscapeCta.link.link)}>
            <a
              className={`text-white uppercase font-semibold xl:text-[13px] text-[12px] leading-[16.25px]
              tracking-[0.2em] cursor-pointer transition-all hover:text-primary underline underline-offset-4 decoration-2`}
            >
              {landscapeCta.link.title}
            </a>
          </Link>
        ))}
    </div>
    <div className={`z-0 absolute top-0 ${fullWidth ? 'w-full' : 'w-[360px]'} h-[68px]`}>
      {landscapeCta.image?.url && (
        <NextImage
          src={landscapeCta.image.url}
          alt={landscapeCta.image?.description || landscapeCta.image?.title}
          layout="fill"
          objectFit="cover"
        />
      )}
    </div>
  </div>
)

export default LandscapeCTA
