// 3rd party imports
import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import throttle from 'lodash/throttle'
import { formStartEvent, formEndEvent } from '@/lib/ga-events'
import useDebounce from '@/hooks/useDebounce'
// Components
import Icon from '@/components/Icon/Icon'
import { server } from '@/config/server'

const DynamicSearchModal = dynamic(() => import('./SearchModal'), {
  loading: () => (
    <div
      className={`search-dropdown flex justify-center bg-white z-[999999999] absolute top-[40px] xl:w-[779px] w-[519px] pb-[24px]`}
    >
      <div className="loader-product ml-[36px] mt-[24px]" />
    </div>
  ),
  ssr: false,
})

const SearchBar = ({
  modalViewHandler,
  search,
  searchPlaceholderText,
  searchRequest,
  setSearch,
  customer,
}) => {
  const router = useRouter()
  const parentRef = useRef()
  const [isInterected, setIsInterected] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const suggestedResult = throttle(() => {
    if (search.suggest && search.suggest.suggested.text) {
      let suggestedResult = search.suggest.suggested.text.toLowerCase()
      let suggestedQuery = search.suggest.query.toLowerCase()
      if (suggestedResult && suggestedResult.includes(suggestedQuery)) {
        return suggestedResult.replace(suggestedQuery, '')
      }
    }
    return null
  }, 500)

  useEffect(() => {
    if (
      search.requestQuery &&
      search.requestQuery?.length > 0 &&
      typeof window !== undefined &&
      window.dataLayer &&
      !isInterected
    ) {
      formStartEvent({
        form_destination: 'search',
        form_length: 1,
        first_field_name: 'searchBar',
        first_field_position: 'top',
        first_field_type: 'text',
      })
      setIsInterected(true)
    }
  }, [search.requestQuery])

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchRequest(debouncedSearchTerm)
    }
    else {
      clearSearch()
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if(searchTerm.length < 3) {
      clearSearch()
    }
  }, [searchTerm])

  const clearSearch = () => {
    setSearch({
      requestQuery: false,
      request: false,
      loading: false,
      data: {},
      categories: [],
      suggest: null,
    })
  }

  const enterPressed = async (event) => {
    const queryLength = event.target.value.length
    const searchQuery = event.target.value
    if (queryLength > 1 && event.key === 'Enter') {
      clearSearch()
      const response = await fetch(`${server}/api/searchspring/autocomplete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          q: searchQuery,
        }),
      })
      const searchData = await response?.json()
      if (typeof window !== undefined && window.dataLayer) {
        dataLayer.push({
          event: 'view_search_results',
          search_term: event.target.value,
        })
        formEndEvent({ form_destination: 'search', form_length: 1 })
        setIsInterected(false)
      }
      if (searchData?.merchandising?.redirect) {
        setSearchTerm('')
        router.push(searchData?.merchandising?.redirect)
      } else {
        router.push(`/search?q=${event.target.value}`)
      }
    }
  }
  
  return (
    <>
      <div
        ref={parentRef}
        className={'flex flex-row grow gap-3 bg-white items-center relative py-3 px-4 rounded-sm'}
      >
        <Icon
          className={`cursor-pointer transition-all hover:fill-primary`}
          viewBox={`0 0 17 17`}
          size={22}
          icon={'search'}
          onClick={modalViewHandler}
        />
        <input hidden /> {/* prevent browsers from autofilling the search field */}
        <input
          aria-label="search"
          autoComplete="disabled"
          className="outline-none grow text-black text-[20px] leading-[24px] bg-transparent focus:outline-none z-2"
          name="search"
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => enterPressed(e)}
          placeholder={searchPlaceholderText || 'What are you looking for?'}
          style={{ zIndex: '1' }}
          type="text"
          value={searchTerm}
        />
        {search.loading ? (
          <div className="h-[20px]">
            <i className="loader"></i>
          </div>
        ) : null}
        {search.request && search.suggest && search.suggest.suggested ? (
          <div className="z-0 suggestion flex gap-3" style={{ zIndex: '0' }}>
            <span className="typed">{search.requestQuery}</span>
            <span className="suggested">{search.suggest && suggestedResult()}</span>
          </div>
        ) : null}
        {search.requestQuery ? (
          <div className="cursor-pointer" onClick={clearSearch}>
            <Icon viewBox={`0 0 26 26`} size={12} icon="close" />
          </div>
        ) : null}
      </div>

      {search.requestQuery?.length > 2 || search.request || search.loading ? (
        <DynamicSearchModal
          search={search}
          searchRequest={searchRequest}
          parentRef={parentRef}
          clearSearch={() => clearSearch()}
          customer={customer}
        />
      ) : null}
      <style jsx>
        {`
          .suggestion {
            position: absolute;
            left: 38px;
            line-height: 24px;
            font-size: 20px;
            width: 230px;
            margin-right: 70px;
          }
          .suggestion .typed {
            opacity: 0;
          }
          .suggestion .suggested {
            font-weight: bold;
          }
        `}
      </style>
    </>
  )
}

export default SearchBar
